import React from "react"
import Head404 from "../components/organisms/head-404/head404"


const CheckAmbiente = () => {
   // tslint:disable-next-line:no-console
   const activeEnv = process.env.GATSBY_ACTIVE_ENV || "no agarra";
   console.log(activeEnv);
   
   return (
   <Head404 />
   )
}

export default CheckAmbiente;
